<style lang="less" scoped>

</style>
<template>
	<div class="content">
		<div v-for="(item,index) in  number" :key="index">
			<author v-if="numIndex==index" :index="index" @authorArr="authorArr" @saveAuthor="saveAuthor"
				@submit="submit" @backtrack="backtrack" :ruleForm="author_list[index]"></author>
		</div>
	</div>
</template>

<script>
	import author from './author.vue'
	export default {
		components: {
			author
		},
		data() {
			return {
				number: 1,
				numIndex: 0,
				author_list: [{
					en_name: "",
					image: "",
					// en_name: "",
					en_nationality: "",
					birth: "",
					en_sex: "",
					en_city: [],
					mobile: "",
					id_card: "",
					en_address: "",
					en_company: "",
					en_position: "",
					school: [{
						en_school_name: "",
						en_post: "",
						start_time: "",
						end_time: ""
					}],
					working: [{
						en_company_name: "",
						en_describe: "",
						company_mobile: "",
						start_time: "",
						end_time: "",
					}],
					award: [{
						en_court: "",
						en_prize: "",
						reward_time: "",
					}]
				}],
				obj: {},
				productionObj:{}
			}
		},
		watch: {
			numIndex(a, b) {
				console.log(this.author_list[a])
				console.log(this.author_list[b])
			}
		},
		mounted() {
			this.number = parseInt(this.$route.query.number)
			// if(this.$route.query.types==2){
this.entriesObj()
		},
		methods: {
			async addAuthor() {
				let _this = this
				try {
					var params = {
						entries_id: _this.$route.query.id,
					}
					_this.$nextTick(async () => {
						let res = await _this.$axios.post('entries/getTempAuthor', params);
						if (res.code == 1) {
							if (res.data.length != 0) {
								_this.author_list = res.data
							} else {
								this.indormationList()
								
							}
							console.log(_this.author_list)
							// this.$router.push('/personalCenter') 
						}
					})
				} catch (error) {
					console.log(error);
				}
			},
			async entriesObj() {
				let _this = this
				try {
					var params = {
						id: _this.$route.query.id,
					}
					_this.$nextTick(async () => {
						let res = await _this.$axios.post('entries/detail', params);
						if (res.code == 1) {
							_this.productionObj=res.data
							this.addAuthor()
						}
					})
				} catch (error) {
					console.log(error);
				}
			},
			async indormationList() {
				try {
					let _this = this
					let params = {
						id: this.$route.query.id
					}
					let res = await this.$axios.get('entries/authorList', {
						params: params
					});
					if (res.code == 1) {
						this.author_list = res.data
						if (res.data.length == 0) {
							// console.log(_this.productionObj.author_lsit)
							this.author_list = [{
								en_name: _this.productionObj.author_lsit[0],
								image: "",
								// en_name: "",
								en_nationality: "",
								birth: "",
								en_sex: "",
								en_city: [],
								mobile: "",
								id_card: "",
								en_address: "",
								en_company: "",
								en_position: "",
								school: [{
									en_school_name: "",
									en_post: "",
									start_time: "",
									end_time: ""
								}],
								working: [{
									en_company_name: "",
									en_post: "",
									company_mobile: "",
									start_time: "",
									end_time: "",
								}],
								award: [{
									en_court: "",
									en_prize: "",
									reward_time: "",
								}]
							}]
						}
						res.data.forEach((item, index) => {
							item.en_city = item.en_city.split("-")
							item.school.forEach((element, i) => {
								element.start_time = element.start_time * 1000
								element.end_time = element.end_time * 1000
							})
							item.working.forEach((element, i) => {
								element.start_time = element.start_time * 1000
								element.end_time = element.end_time * 1000
							})
							item.award.forEach((element, i) => {
								element.reward_time = element.reward_time * 1000
							})
						})
					}
				} catch (error) {
					console.log(error);
				}
			},
			async submit(a, b) {
				console.log(a)
				if (this.author_list[b]) {
					this.author_list[b] = a
				} else {
					this.author_list.push(a)
				}
				let _this = this
				try {
					var params = {
						id: this.$route.query.id,
					}
					console.log(_this.author_list)
					_this.author_list.forEach((item, index) => {
						_this.$set(params, 'author_list[' + index + '][image]', item.image)
						_this.$set(params, 'author_list[' + index + '][en_name]', item.en_name)
						_this.$set(params, 'author_list[' + index + '][en_nationality]', item.en_nationality)
						_this.$set(params, 'author_list[' + index + '][birth]', item.birth)
						_this.$set(params, 'author_list[' + index + '][en_sex]', item.en_sex)
						_this.$set(params, 'author_list[' + index + '][en_city]', item.en_city[0] + '-' + item
							.en_city[1] + '-' + item
							.en_city[2])
						_this.$set(params, 'author_list[' + index + '][mobile]', item.mobile)
						_this.$set(params, 'author_list[' + index + '][id_card]', item.id_card)
						_this.$set(params, 'author_list[' + index + '][en_address]', item.en_address)
						_this.$set(params, 'author_list[' + index + '][en_company]', item.en_company)
						_this.$set(params, 'author_list[' + index + '][en_position]', item.en_position)
						item.school.forEach((element, i) => {
							_this.$set(params, 'author_list[' + index + '][school][' + i +
								'][en_school_name]', element.en_school_name)
							_this.$set(params, 'author_list[' + index + '][school][' + i +
								'][en_post]', element.en_post)
							_this.$set(params, 'author_list[' + index + '][school][' + i +
								'][start_time]', element.start_time / 1000)
							_this.$set(params, 'author_list[' + index + '][school][' + i +
								'][end_time]', element.end_time / 1000)
						})
						item.working.forEach((element, i) => {
							_this.$set(params, 'author_list[' + index + '][working][' + i +
								'][en_company_name]', element.en_company_name)
							_this.$set(params, 'author_list[' + index + '][working][' + i +
								'][company_mobile]', element.company_mobile)
							_this.$set(params, 'author_list[' + index + '][working][' + i +
								'][en_post]', element.en_post)
							_this.$set(params, 'author_list[' + index + '][working][' + i +
								'][start_time]', element.start_time / 1000)
							_this.$set(params, 'author_list[' + index + '][working][' + i +
								'][end_time]', element.end_time / 1000)
						})
						item.award.forEach((element, i) => {
							_this.$set(params, 'author_list[' + index + '][award][' + i +
								'][en_court]', element.en_court)
							_this.$set(params, 'author_list[' + index + '][award][' + i +
								'][en_prize]', element.en_prize)
							_this.$set(params, 'author_list[' + index + '][award][' + i +
								'][reward_time]', element.reward_time / 1000)
						})
					})
					this.$nextTick(async () => {
						console.log(params)
						let res = await _this.$axios.post('entries/addAuthor', params);
						console.log(res)
						if (res.code == 1) {
							_this.$message({
								offset: 100,
								message: res.msg,
								type: 'success'
							});
							this.$router.push('/personalCenter')
						}
					})
				} catch (error) {
					console.log(error);
				}
			},
			backtrack(a, b) {
				if (this.author_list[b]) {
					this.author_list[b] = a
				} else {
					this.author_list.push(a)
				}
				this.numIndex = b - 1
			},
			async saveAuthor(a, b) {
				let _this = this
				if (this.author_list[b]) {
					this.author_list[b] = a
				} else {
					this.author_list.push(a)
				}
				console.log(this.author_list)
				try {

					var params = {
						entries_id: _this.$route.query.id,
						author_list: _this.author_list
					}
					_this.$nextTick(async () => {
						let res = await _this.$axios.post('entries/saveTempAuthor', params);
						console.log(res)
						if (res.code == 1) {
							_this.$message({
								offset: 100,
								message: res.msg,
								type: 'success'
							});
							// this.$router.push('/personalCenter') 
						}
					})
				} catch (error) {
					console.log(error);
				}
			},
			authorArr(a, b) {
				console.log(a, b)
				if (this.author_list[b]) {
					this.author_list[b] = a
					if (!this.author_list[b + 1]) {
						this.author_list.push({
							en_name: this.productionObj.author_lsit[b+1],
							image: "",
							// en_name: "",
							en_nationality: "",
							birth: "",
							en_sex: "",
							en_city: [],
							mobile: "",
							id_card: "",
							en_address: "",
							en_company: "",
							en_position: "",
							school: [{
								en_school_name: "",
								en_post: "",
								start_time: "",
								end_time: ""
							}],
							working: [{
								en_company_name: "",
								en_describe: "",
								company_mobile: "",
								start_time: "",
								end_time: "",
							}],
							award: [{
								en_court: "",
								en_prize: "",
								reward_time: "",
							}]
						})
					}

				} else {
					this.author_list.push(a)
				}
				console.log(this.author_list)
				this.$nextTick(() => {
					this.numIndex = b + 1
				})

				console.log(this.numIndex)
			}
		}
	}
</script>
